import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import createSagaMiddleware from "redux-saga"
import logger from "redux-logger"

import { createBrowserHistory } from "history"
import { createReduxHistoryContext } from "redux-first-history"

import reducers from "./reducers"
import sagas from "./sagas"

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  })

const sagaMiddleware = createSagaMiddleware()

let middlewares = [thunk, sagaMiddleware, routerMiddleware]
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  middlewares = [thunk, sagaMiddleware, routerMiddleware, logger]
}

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [],
}

const ConsolePersistConfig = {
  key: "Auth",
  storage,
  whitelist: ["serial_number"],
}

const rootReducer = combineReducers({
  router: routerReducer,
  Agenda: reducers.Agenda,
  App: reducers.App,
  Call: reducers.Call,
  Console: persistReducer(ConsolePersistConfig, reducers.Console),
  Message: reducers.Message,
  News: reducers.News,
  Radio: reducers.Radio,
  Weather: reducers.Weather,
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistedReducer,
  undefined,
  composeEnhancers(applyMiddleware(...middlewares))
  // compose(applyMiddleware(...middlewares))
)

sagaMiddleware.run(sagas)

const persistor = persistStore(store)
const history = createReduxHistory(store)

export { store, persistor, history }
