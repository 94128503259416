import * as actionTypes from "../actions/actionTypes";
import produce from "immer";
import { _processUpcomingAlarmsReminders } from "../../utils/helper";

const INITIAL_STATE = {
  agendaList: {
    data: {},
    isLoading: false,
  },
  upcomingAlarms: [],
  upcomingReminders: [],
};

export default function agendaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Pairing
    case actionTypes.REQUEST_GET_AGENDA_LIST: {
      return produce(state, (draft) => {
        draft.agendaList.data = [];
        draft.agendaList.isLoading = true;
      });
    }
    case actionTypes.SUCCESS_GET_AGENDA_LIST: {
      return produce(state, (draft) => {
        draft.agendaList.data = action.data;
        draft.agendaList.isLoading = false;
        const alarmsAndRemindersForToday = _processUpcomingAlarmsReminders(
          action.data
        );

        if (alarmsAndRemindersForToday.upcomingAlarms !== undefined) {
          draft.upcomingAlarms = alarmsAndRemindersForToday.upcomingAlarms;
        }

        if (alarmsAndRemindersForToday.upcomingReminders !== undefined) {
          draft.upcomingReminders =
            alarmsAndRemindersForToday.upcomingReminders;
        }
      });
    }
    case actionTypes.FAIL_GET_AGENDA_LIST: {
      return produce(state, (draft) => {
        draft.agendaList.isLoading = false;
      });
    }
    case actionTypes.SET_ALARMS: {
      return produce(state, (draft) => {
        draft.upcomingAlarms = action.data;
      });
    }
    case actionTypes.SET_REMINDERS: {
      return produce(state, (draft) => {
        draft.upcomingReminders = action.data;
      });
    }

    default:
      return state;
  }
}
