import * as actionTypes from "./actionTypes";

export const getAgendaList = (data) => {
  return {
    type: actionTypes.REQUEST_GET_AGENDA_LIST,
    data,
  };
};

export const getAgendaListSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_AGENDA_LIST,
    data,
  };
};

export const getAgendaListFail = () => {
  return {
    type: actionTypes.FAIL_GET_AGENDA_LIST,
  };
};

export const setAgendaList = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_AGENDA_LIST,
    data,
  };
};

export const setAlarms = (data) => {
  return {
    type: actionTypes.SET_ALARMS,
    data,
  };
};

export const setReminders = (data) => {
  return {
    type: actionTypes.SET_REMINDERS,
    data,
  };
};
