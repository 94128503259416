import React from "react";
import styled from "styled-components";

const MainContainer = (props) => {
  return (
    <MainContainerWrapper className={props.className}>
      {props.children}
    </MainContainerWrapper>
  );
};

const MainContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export default MainContainer;
