import * as actionTypes from "./actionTypes"

export const loading = (enable = true) => {
  return {
    type: actionTypes.LOADER,
    enable,
  }
}

export const triggerModal = (data) => {
  return {
    type: actionTypes.TRIGGER_MODAL,
    data,
  }
}

export const dismissModal = () => {
  return {
    type: actionTypes.DISMISS_MODAL,
  }
}

export const setToastStatus = (type, status) => {
  return {
    type: actionTypes.SET_TOAST_STATUS,
    data: { type, status },
  }
}
