import api from "./../../utils/api";
// import { store } from './../../redux/store';

const pathURL = "console";

export const getContactList = () => {
  const url = `${pathURL}/videomenu`;
  return api.get(url);
};

export const callAppUser = (params) => {
  const url = `${pathURL}/callfirebase`;
  return api.post(url, params);
};
