import React from "react"
import { Clock } from "../atoms"
import styled from "styled-components"
import Color from "../../assets/color"
import { mainTransition } from "../../assets/css_variable"

const LargeClock = (props) => {
  return (
    <TimeWrapper isSleepMode={props.isSleepMode} style={props.timeWrapperStyle}>
      <ClockWrapper style={props.clockWrapperStyle}>
        <Day>
          <Clock
            format={"dddd"}
            ticking={true}
            // timezone={"Europe/Amsterdam"}
          />
        </Day>
        <Time>
          <Clock
            format={"HH:mm"}
            ticking={true}
            // timezone={"Europe/Amsterdam"}
          />
        </Time>
        <Date>
          <Clock
            format={"D MMMM YYYY"}
            ticking={true}
            // timezone={"Europe/Amsterdam"}
          />
        </Date>
      </ClockWrapper>
    </TimeWrapper>
  )
}

const TimeWrapper = styled.div`
  position: relative;
  z-index: 0;
  width: 90%;
  margin: auto;

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
    width: 100%;
    border-radius: 50%;
    // background-color: ${(props) =>
      props.isSleepMode === true ? "transparent" : Color.blue};
  }
`

const ClockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${Color.white};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Day = styled.div`
  font-size: 2.65vw;
  line-height: 1;
  // font-weight: 500;
  transition: ${mainTransition};

  time {
    text-transform: capitalize;
  }
`

const Time = styled.div`
  font-size: 9vw;
  line-height: 1.4;
  font-weight: bold;
  transition: ${mainTransition};
`

const Date = styled.div`
  font-size: 2.65vw;
  line-height: 1;
  // font-weight: 500;
  transition: ${mainTransition};
`

export default LargeClock
