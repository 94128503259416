import * as actionTypes from "./actionTypes";

export const getNewsList = (data) => {
  return {
    type: actionTypes.REQUEST_GET_NEWS,
    data,
  };
};

export const getNewsListSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_NEWS,
    data,
  };
};

export const getNewsListFail = () => {
  return {
    type: actionTypes.FAIL_GET_NEWS,
  };
};
