import _ from "lodash"
import produce from "immer"
import { useEffect, useRef } from "react"
import moment from "moment-timezone"
import "moment/locale/nl"

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
/*
  Example : _difference(this.currentAddresscart, prevProps.cart)
  Performance is greater than JSON.stringify compare
*/
export const _difference = (object, base) => {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] =
        _.isObject(value) && _.isObject(base[key])
          ? _.difference(value, base[key])
          : value
    }
  })
}

export const _objectIsEqual = (object, base) => {
  return _.isEmpty(_difference(object, base))
}

export const _filterNewsList = (news) => {
  if (_.get(news, "rss.channel.item")) {
    return _processNewsItem([...news.rss.channel.item])
  }
  return []
}

export const _processNewsItem = (newsList) => {
  return produce([], (draft) => {
    newsList.forEach((news) => {
      const { description, enclosure, title } = news

      /* 
        Type    Description 
        1       Contain Image 
        2       Without Image
       */

      let newsDescription = _.has(description, "_cdata")
        ? description._cdata
        : description._text

      const maxDescLength = 480

      // strip any html tags
      const regex = /<p>(.*?)<\/p>/
      const corresp = regex.exec(newsDescription)
      const cleanDescription = corresp ? corresp[1] : ""

      const newsObject = {
        type: enclosure._attributes ? 1 : 2,
        title: title._cdata,
        description:
          cleanDescription.length > maxDescLength
            ? _.truncate(cleanDescription, {
                length: maxDescLength,
                separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
                // omission: ' [..]',
              })
            : cleanDescription,
        enclosure: enclosure._attributes,
      }

      draft.push(newsObject)
    })
  })
}

export const useDeepEffect = (effectFunc, deps) => {
  const isFirst = useRef(true)
  const prevDeps = useRef(deps)

  useEffect(() => {
    const isEqual = _objectIsEqual(prevDeps, deps)

    if (isFirst.current || !isEqual) {
      effectFunc()
    }

    isFirst.current = false
    prevDeps.current = deps
  }, deps)
}

export const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const _padZeroTimeHelper = (num) => {
  // PREPEND HR, MIN, SEC WITH 0 (IF < 10)
  if (num < 10) {
    num = "0" + num
  } else {
    num = num.toString()
  }
  return num
}

export const _parseTimeString = (timeStr) => {
  let parsed

  if (timeStr !== "") {
    const time_array = timeStr.split(":")

    const hour = parseInt(time_array[0])
    const minute = parseInt(time_array[1])
    const seconds = parseInt(time_array[2])

    parsed = {
      hour,
      minute,
      seconds,
    }
  }
  return parsed
}

export const _processUpcomingAlarmsReminders = (data) => {
  let upcomingAlarms, upcomingReminders
  moment.locale("nl")
  const dateFormat = "YYYY-MM-DD"
  const days = { today: 0, tomorrow: 1, overmorrow: 2 }
  const reminders = {
    "10minbefore": {
      type: "minutes",
      value: 10,
    },
    "30minbefore": {
      type: "minutes",
      value: 30,
    },
    "1hourbefore": {
      type: "hours",
      value: 1,
    },
    "2daysbefore": {
      type: "days",
      value: 2,
    },
  }
  const responseData = data

  // START - TODAY'S UPCOMING ALARMS
  const todayAgenda = responseData.today
  if (
    typeof todayAgenda === "object" &&
    Object.keys(todayAgenda).length !== 0
  ) {
    const alarmsForToday = todayAgenda.filter(function (agenda) {
      const now = moment()
      const todayDate = now.format(dateFormat)
      const currentAgendaTime = moment(`${todayDate} ${agenda.time}`)

      return (
        agenda.alarm === 1 && Date.parse(currentAgendaTime) > Date.parse(now)
      )
    })
    if (alarmsForToday.length !== 0) {
      upcomingAlarms = alarmsForToday
    }
  }
  // END - TODAY'S UPCOMING ALARMS

  // START - TODAY'S UPCOMING REMINDERS
  // filter agendas that have reminder and is upcoming
  const agendasWithReminder = Object.fromEntries(
    Object.keys(responseData).map((day) => {
      const now = moment()
      let dayDate = now.format(dateFormat)

      if (day !== "today") {
        dayDate = now.add(days[day], "days").format(dateFormat)
      }

      const hasReminders = responseData[day].filter(function (agenda) {
        const currentAgendaTime = moment(`${dayDate} ${agenda.time}`)

        return (
          agenda.reminder != null &&
          agenda.reminder !== "noreminder" &&
          Date.parse(currentAgendaTime) > Date.parse(now)
        )
      })

      // add agenda date to each agenda
      const agendaData = hasReminders.map((agenda) => {
        const agendaDateTime = `${dayDate} ${agenda.time}`
        const agendaReminder = agenda.reminder

        // get time of reminder
        let reminderMoment = moment(agendaDateTime)
        const datetimeFormatted =
          reminderMoment.format("D MMMM, HH:mm") + " uur"

        reminderMoment = reminderMoment.subtract(
          reminders[agendaReminder].value,
          reminders[agendaReminder].type
        )
        reminderMoment = reminderMoment.format("HH:mm")

        return {
          ...agenda,
          datetime: agendaDateTime,
          reminderTime: reminderMoment,
          datetimeFormatted: datetimeFormatted,
        }
      })

      return [day, agendaData]
    })
  )

  // check each reminder if it needs to be triggered today
  const notifyToday = []
  Object.keys(agendasWithReminder).forEach((day) => {
    const reminderData = agendasWithReminder[day].filter(function (agenda) {
      const agendaTime = moment(agenda.datetime)

      // get time of reminder
      const agendaReminder = agenda.reminder
      const reminderMoment = agendaTime.subtract(
        reminders[agendaReminder].value,
        reminders[agendaReminder].type
      )

      const isUpcoming =
        moment().isBefore(reminderMoment) &&
        Date.parse(reminderMoment.startOf("day")) ===
          Date.parse(moment().startOf("day"))

      return isUpcoming
    })

    notifyToday.push(...reminderData)
  })

  if (notifyToday.length !== 0) {
    upcomingReminders = notifyToday
  }
  // END - TODAY'S UPCOMING REMINDERS

  return {
    upcomingAlarms,
    upcomingReminders,
  }
}

export const isCallButton = (button) => {
  return button.value === "video call" && button.details !== null
}

export const _triggerKeyPress = (type, key) => {
  const buttonEvent = new KeyboardEvent(type, {
    key: key,
    code: `Key${key.toUpperCase()}`,
  })
  window.dispatchEvent(buttonEvent)
}
