import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  weatherList: {
    data: [],
    isLoading: false,
  },
};

export default function messageReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Pairing
    case actionTypes.REQUEST_GET_WEATHER_LIST: {
      return produce(state, (draft) => {
        draft.weatherList.data = [];
        draft.weatherList.isLoading = true;
      });
    }
    case actionTypes.SUCCESS_GET_WEATHER_LIST: {
      return produce(state, (draft) => {
        draft.weatherList.data = action.data;
        draft.weatherList.isLoading = false;
      });
    }
    case actionTypes.FAIL_GET_WEATHER_LIST: {
      return produce(state, (draft) => {
        draft.weatherList.isLoading = false;
      });
    }

    default:
      return state;
  }
}
