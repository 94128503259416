import { all, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "redux-first-history";

import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";

export function* getAgendaList() {
  yield takeEvery(actionTypes.REQUEST_GET_AGENDA_LIST, function* (action) {
    yield put(actions.loading(true));
    try {
      const response = yield API.getAgendaList();

      if (response.data) {
        yield put(actions.getAgendaListSuccess(response.data));
      } else {
        yield put(actions.getAgendaListFail());
      }

      yield put(actions.loading(false));
    } catch (error) {
      if (error.response.status === 401) yield put(push("/pair-pedle-unit"));
      yield put(actions.getAgendaListFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* agendaSaga() {
  yield all([fork(getAgendaList)]);
}
