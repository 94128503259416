import Agenda from "./agenda_reducer";
import App from "./app_reducer";
import Call from "./call_reducer";
import Console from "./console_reducer";
import Message from "./message_reducer";
import News from "./news_reducer";
import Radio from "./radio_reducer";
import Weather from "./weather_reducer";

export default {
  Agenda,
  App,
  Call,
  Console,
  Message,
  News,
  Radio,
  Weather,
};
