import * as actionTypes from "./actionTypes"

export const pairing = (data) => {
  return {
    type: actionTypes.REQUEST_PAIRING,
    data,
  }
}

export const pairingSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_PAIRING,
    data,
  }
}

export const pairingFail = () => {
  return {
    type: actionTypes.FAIL_PAIRING,
  }
}

export const getMenuList = (data) => {
  return {
    type: actionTypes.REQUEST_GET_MENU_LIST,
    data,
  }
}

export const getMenuListSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_MENU_LIST,
    data,
  }
}

export const getMenuListFail = () => {
  return {
    type: actionTypes.FAIL_GET_MENU_LIST,
  }
}

export const setPedleSocket = (data) => {
  return {
    type: actionTypes.SET_PEDLE_SOCKET,
    data,
  }
}

export const setMenuCategory = (data) => {
  return {
    type: actionTypes.SET_MENU_CATEGORY,
    data,
  }
}

export const checkPairing = (data, redirectToPairing = true) => {
  return {
    type: actionTypes.REQUEST_CHECK_PAIRING,
    data,
    redirectToPairing,
  }
}

export const checkPairingSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_CHECK_PAIRING,
    data,
  }
}

export const checkPairingFail = () => {
  return {
    type: actionTypes.FAIL_CHECK_PAIRING,
  }
}

export const getSerialNumber = (data) => {
  return {
    type: actionTypes.REQUEST_GET_SERIAL_NUMBER,
    data,
  }
}

export const getSerialNumberSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_SERIAL_NUMBER,
    data,
  }
}

export const getSerialNumberFail = () => {
  return {
    type: actionTypes.FAIL_GET_SERIAL_NUMBER,
  }
}

export const setStream = (data) => {
  return {
    type: actionTypes.SET_STREAM,
    data,
  }
}

export const clearStream = () => {
  return {
    type: actionTypes.CLEAR_STREAM,
  }
}

export const logActivity = (data) => {
  return {
    type: actionTypes.REQUEST_CREATE_ACTIVITY_LOG,
    data,
  }
}
