import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  radio: {
    data: {},
    isLoading: false,
  },
};

export default function radioReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Pairing
    case actionTypes.REQUEST_GET_NEWS: {
      return produce(state, (draft) => {
        draft.radio.data = {};
        draft.radio.isLoading = true;
      });
    }
    case actionTypes.SUCCESS_GET_NEWS: {
      return produce(state, (draft) => {
        draft.radio.data = action.data;
        draft.radio.isLoading = false;
      });
    }
    case actionTypes.FAIL_GET_NEWS: {
      return produce(state, (draft) => {
        draft.radio.isLoading = false;
      });
    }

    default:
      return state;
  }
}
