import * as actionTypes from "./actionTypes";

export const getMessageList = (data) => {
  return {
    type: actionTypes.REQUEST_GET_MESSAGE_LIST,
    data,
  };
};

export const getMessageListSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_MESSAGE_LIST,
    data,
  };
};

export const getMessageListFail = () => {
  return {
    type: actionTypes.FAIL_GET_MESSAGE_LIST,
  };
};
