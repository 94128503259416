import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Color from "../../assets/color"
import {
  buttonCategories,
  defaultCategoryMenu,
  getButtonIconByType,
} from "../../utils/constant"
import { ControllerButton } from "../atoms"

import { setMenuCategory } from "../../redux/actions"

const HomeButton = ({ onClick, history, showShadow }) => {
  let onClickButton = onClick ? onClick : () => history.push("home")
  return (
    <ControllerButton
      className={`active is-last-item ${showShadow ? "shadow" : ""}`}
      onClick={onClickButton}
    >
      <img src={getButtonIconByType(defaultCategoryMenu)} alt="" />
    </ControllerButton>
  )
}

const Header = (props) => {
  return (
    <HeaderDiv className="header" {...props}>
      <HeaderWrapper>
        {props.isSleepMode !== true && (
          <React.Fragment>
            <LeftWrapper
              disableLeftWrapperPadding={props.disableLeftWrapperPadding}
            >
              {props.children}
            </LeftWrapper>
            <RightWrapper>
              {/* Home */}
              {!!props.isShowMenuCategory && props.isSleepMode !== true && (
                <React.Fragment>
                  {buttonCategories.map((item, index) => (
                    <ControllerButton
                      key={item.type}
                      className={`${
                        props.menuList.category === item.type
                          ? "active-menu"
                          : ""
                      } ${
                        index === buttonCategories.length - 1
                          ? "is-last-item"
                          : ""
                      }`}
                      onClick={() => {
                        props.setMenuCategory(item.type)
                        props.history.push("home")
                      }}
                    >
                      <img src={item.icon} alt="" />
                    </ControllerButton>
                  ))}
                </React.Fragment>
              )}

              {/* Default */}
              {!props.disableHomeButton &&
                !props.isShowMenuCategory &&
                !props.isModalScreen &&
                !props.isCallScreen &&
                !props.isGamesScreen &&
                !props.isPairScreen && <HomeButton history={props.history} />}

              {/* Pairing */}
              {!!props.isPairScreen && (
                <HomeButton onClick={() => props.history.push("/")} />
              )}

              {/* Call */}
              {!!props.isCallScreen &&
                !props.isModalScreen &&
                !props.isShowPageNav && (
                  <HomeButton
                    onClick={props.callProps.onClickHome}
                    showShadow={props.transparentHeader}
                  />
                )}

              {/* Modal */}
              {!!props.isModalScreen && (
                <HomeButton onClick={props.modalProps.onClickHome} />
              )}

              {/* Games */}
              {!!props.isGamesScreen && (
                <HomeButton
                  history={props.history}
                  showShadow={props.transparentHeader}
                />
              )}
            </RightWrapper>
          </React.Fragment>
        )}
      </HeaderWrapper>
    </HeaderDiv>
  )
}

const HeaderDiv = styled.div`
  min-height: 15vh;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isSleepMode === true
      ? "transparent"
      : props.transparentHeader
      ? "transparent"
      : props.isAltBg
      ? Color.hippieBlue
      : Color.blue};

  ${(props) =>
    props.transparentHeader &&
    `
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      `}
`

const HeaderWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`

const LeftWrapper = styled.div`
  flex: 1;
  padding: ${(props) => (props.disableLeftWrapperPadding ? "" : "1rem 2.5rem")};
  font-size: 2.65vw;
  line-height: 1.1;
  color: #fff;
  font-weight: 600;
  align-self: center;
`

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const mapStateToProps = (state) => {
  return {
    menuList: state.Console.menuList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMenuCategory: (params) => dispatch(setMenuCategory(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))
