import { all } from "redux-saga/effects";
import agendaSaga from "./agenda_saga";
import callSaga from "./call_saga";
import consoleSaga from "./console_saga";
import messageSaga from "./message_saga";
import newsSaga from "./news_saga";
import radioSaga from "./radio_saga";
import weatherSaga from "./weather_saga";

export default function* rootSaga() {
  yield all([
    agendaSaga(),
    callSaga(),
    consoleSaga(),
    messageSaga(),
    newsSaga(),
    radioSaga(),
    weatherSaga(),
  ]);
}
