import api from "./../../utils/api"
// import { store } from './../../redux/store';

const pathURL = "console"

export const getMenuList = () => {
  const url = `${pathURL}/menu`
  return api.get(url)
}

export const pairing = (params) => {
  const url = `pedle/pairing`
  return api.post(url, params)
}

export const checkPairingStatus = (params) => {
  const url = `pedle/pairingstatuscheck`
  return api.post(url, params)
}

export const getSerialNumber = () => {
  const url = "serial.php"
  return api.get(url, {
    baseURL: "http://127.0.0.1/",
  })
}

export const requestToken = (params) => {
  const url = "pedle/requesttoken"
  return api.post(url, params)
}

export const logActivity = (params) => {
  const url = `${pathURL}/activity`
  return api.post(url, params)
}
