import React, { forwardRef } from "react"
import styled from "styled-components"

const CallPartnerVideo = forwardRef((props, ref) => {
  return (
    <Video
      playsInline
      ref={ref}
      autoPlay
      isPartner={props.isPartner}
      isSpeakerOn={props.isSpeakerOn}
      muted={props.isPartner ? false : true}
    />
  )
})

const Video = styled.video`
  width: auto;
  height: 100%;

  ${(props) =>
    props.isPartner === true
      ? `
      max-width: 100%;
      opacity: ${props.isSpeakerOn ? 1 : 0};
    `
      : `
      height: auto;
      max-width: 25%;
      position: fixed;
      bottom: 3vw;
      right: 20%;
      //border: 2px solid #dbdbdb;
  `}
`

export default CallPartnerVideo
