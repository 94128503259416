const Color = {
  primaryYellow: "#FFE304",
  primaryBlue: "#02A4FF",
  primaryRed: "#FC0C0C",
  primaryGreen: "#00FF19",
  primaryOrange: "#FC7A02",

  hippieBlue: "#5492B1",
  blueBayoux: "#546686",
  blueDianne: "#235C6C",
  blue: "#27303F",
  darkBlue: "#1B222C",
  darkerBlue: "#151A22",
  darkestBlue: "#131820",
  white: "#FFFFFF",
  black: "#1B222C",
  grey: "rgba(255,255,255,0.3)",
}

export default Color
