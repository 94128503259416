import api from "./../../utils/api"
// import { store } from './../../redux/store';
// import { loading } from '../../redux/actions';

// const pathURL = "news";

export const getNews = (newsUrl) => {
  const url = `${process.env.REACT_APP_CORS_URL}/${newsUrl}.xml?format=xml`

  return api.get(url, {
    baseURL: "",
    headers: {
      accept: "application/xml",
      "content-type": "application/xml",
    },
  })
}
