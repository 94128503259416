import { all, takeEvery, put, fork } from "redux-saga/effects"
import { push } from "redux-first-history"

import * as actionTypes from "../actions/actionTypes"
import * as actions from "../actions"
import * as API from "../api"

export function* pairing() {
  yield takeEvery(actionTypes.REQUEST_PAIRING, function* (action) {
    //yield put(actions.loading(true));

    try {
      const response = yield API.pairing(action.data)
      if (response.status === 200 && response.data.status === "Paired") {
        yield put(
          actions.pairingSuccess({
            token: response.data.token,
            group_code: response.data.groupCode,
            groupDetails: {
              name: response.data.name,
              avatar: response.data.avatar,
            },
          })
        )
        yield put(push("/home"))
      } else {
        yield put(actions.pairingFail())
      }
      //yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.pairingFail())
      // yield put(actions.loading(false));
    }
  })
}

export function* checkPairing() {
  yield takeEvery(actionTypes.REQUEST_CHECK_PAIRING, function* (action) {
    yield put(actions.loading(true))

    try {
      const response = yield API.checkPairingStatus(action.data)
      if (response.status === 200 && response.data.success === "Paired.") {
        const tokenResponse = yield API.requestToken(action.data)
        if (tokenResponse.data.token) {
          yield put(
            actions.checkPairingSuccess({
              token: tokenResponse.data.token,
              group_code: response.data.groupCode,
              groupDetails: {
                name: response.data.name,
                avatar: response.data.avatar,
              },
            })
          )
          yield put(push("/home"))
        } else {
          yield put(actions.checkPairingFail())
          if (!!action.redirectToPairing) yield put(push("/pair-pedle-unit"))
        }
      } else {
        yield put(actions.checkPairingFail())
        if (!!action.redirectToPairing) yield put(push("/pair-pedle-unit"))
      }
      yield put(actions.loading(false))
    } catch (error) {
      yield put(actions.checkPairingFail())
      if (!!action.redirectToPairing) yield put(push("/pair-pedle-unit"))
      yield put(actions.loading(false))
    }
  })
}

export function* getMenuList() {
  yield takeEvery(actionTypes.REQUEST_GET_MENU_LIST, function* (action) {
    yield put(actions.loading(true))

    if (action.data !== undefined) {
      yield put(actions.getMenuListSuccess(action.data))
      yield put(actions.loading(false))
    } else {
      try {
        const response = yield API.getMenuList()
        if (response.data) {
          yield put(actions.getMenuListSuccess(response.data.data))
        } else {
          yield put(actions.getMenuListFail())
        }
        yield put(actions.loading(false))
      } catch (error) {
        if (error.response.status === 401) yield put(push("/pair-pedle-unit"))
        yield put(actions.getMenuListFail())
        yield put(actions.loading(false))
      }
    }
  })
}

export function* getSerialNumber() {
  yield takeEvery(actionTypes.REQUEST_GET_SERIAL_NUMBER, function* (action) {
    yield put(actions.loading(true))

    try {
      const response = yield API.getSerialNumber(action.data)
      if (response.status === 200 && response.data.serial !== undefined) {
        yield put(actions.getSerialNumberSuccess(response.data.serial))
      } else {
        yield put(actions.getSerialNumberFail())
      }
      yield put(actions.loading(false))
    } catch (error) {
      yield put(actions.getSerialNumberFail())
      yield put(actions.loading(false))
    }
  })
}

export function* logActivity() {
  yield takeEvery(actionTypes.REQUEST_CREATE_ACTIVITY_LOG, function* (action) {
    yield put(actions.loading(false))

    try {
      yield API.logActivity(action.data)
      // console.log("logActivity API response", response)
      yield put(actions.loading(false))
    } catch (error) {
      // console.log("logActivity API error", error)
      yield put(actions.loading(false))
    }
  })
}

export default function* consoleSaga() {
  yield all([
    fork(pairing),
    fork(getMenuList),
    fork(checkPairing),
    fork(getSerialNumber),
    fork(logActivity),
  ])
}
