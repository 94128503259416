import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, history, persistor } from "./redux/store";
import Router from "./router";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Router history={history} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
