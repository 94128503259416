import React, { Suspense, lazy, useEffect, useState, useRef } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"
import _ from "lodash"
import io from "socket.io-client"
import Pusher from "pusher-js"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { connect } from "react-redux"

// UI & Style
import Loader from "./screens/Loader"
import Modal from "./screens/Modal"
import styled from "styled-components"
import Color from "../src/assets/color"
import iconBell from "../src/assets/icons/icon-bell.svg"

import {
  useDeepEffect,
  _padZeroTimeHelper,
  _parseTimeString,
  isCallButton,
} from "./utils/helper"
import {
  alarmDismissKeys,
  buttonNumberKeys,
  buttonCategoryKeys,
  dotKey,
} from "./utils/constant"

import {
  setStream,
  setPedleSocket,
  triggerModal,
  setToastStatus,
  setMenuCategory,
} from "./redux/actions"

const reminderSound = new Audio(require("./assets/sounds/reminder.mp3"))

const Agenda = lazy(() => import("../src/screens/Agenda"))
const Home = lazy(() => import("../src/screens/Home"))
const News = lazy(() => import("../src/screens/News"))
const Message = lazy(() => import("../src/screens/Message"))
const PairPedle = lazy(() => import("../src/screens/PairPedle"))
const Radio = lazy(() => import("../src/screens/Radio"))
const SleepScreen = lazy(() => import("../src/screens/SleepScreen"))
const SplashScreen = lazy(() => import("../src/screens/SplashScreen"))
const InCall = lazy(() => import("../src/screens/InCall"))
const VideoCall = lazy(() => import("../src/screens/VideoCall"))
const Weather = lazy(() => import("../src/screens/Weather"))

// Games
const CatchApples = lazy(() => import("../src/screens/Games/CatchApples"))

const AllRoutes = () => {
  return (
    <Switch>
      <Route exact path="/agenda" render={(props) => <Agenda {...props} />} />
      <Route exact path="/home" render={(props) => <Home {...props} />} />
      <Route exact path="/news" render={(props) => <News {...props} />} />
      <Route exact path="/message" render={(props) => <Message {...props} />} />
      <Route
        exact
        path="/pair-pedle-unit"
        render={(props) => <PairPedle {...props} />}
      />
      <Route exact path="/radio" render={(props) => <Radio {...props} />} />
      <Route
        exact
        path="/sleep-screen"
        render={(props) => <SleepScreen {...props} />}
      />
      <Route
        exact
        path="/splash-screen"
        render={(props) => <SplashScreen {...props} />}
      />
      <Route exact path="/in-call" render={(props) => <InCall {...props} />} />
      <Route
        exact
        path="/video-call"
        render={(props) => <VideoCall {...props} />}
      />
      <Route exact path="/weather" render={(props) => <Weather {...props} />} />

      <Route
        exact
        path="/catch-apples"
        render={(props) => <CatchApples {...props} />}
      />
      <Redirect to="/splash-screen" />
    </Switch>
  )
}

const AppRouter = (props) => {
  // START For alarms and reminders
  // specify screens to exclude alarms & reminders here
  const excludedScreensForAlarmReminders = [
    // "/catch-apples"
  ]

  const [alarmTime, setAlarmTime] = useState()
  const [alarmData, setAlarmData] = useState()
  const [reminderTime, setReminderTime] = useState()
  const [reminderData, setReminderData] = useState()

  const upcomingRemindersRef = useRef()
  const checkAlarmInterval = useRef()
  const updateAlarmInterval = useRef()
  const checkReminderInterval = useRef()
  const updateReminderInterval = useRef()
  // END For alarms and reminders

  // For sleep mode
  const sleepTimerRef = useRef()

  // For videocalling
  // const [receivingCall, setReceivingCall] = useState(false)
  const [caller, setCaller] = useState("")
  const [callerData, setCallerData] = useState()
  const [callerSignal, setCallerSignal] = useState()

  const socket = useRef()
  const menuList = useRef()
  const currentURL = useRef("")
  const groupCode = useRef("")
  const onlineUsers = useRef()

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyRelease)
  }, [])

  useEffect(() => {
    if (props.group_code !== "" && props.access_token !== "") {
      const pusherRef = new Pusher(process.env.REACT_APP_WS_KEY, {
        cluster: process.env.REACT_APP_WS_CLUSTER,
        wsHost: process.env.REACT_APP_WS_HOST,
        wsPort: 6001,
        wssPort: 6001,
        authEndpoint: `${process.env.REACT_APP_BASEURL}/console/menu`,
        auth: {
          headers: {
            Authorization: `Bearer ${props.access_token}`,
          },
        },
        enabledTransports: ["ws", "wss"],
        forceTLS: true, //env === 'live' ? true : false,
      })
      const menuChannel = pusherRef.subscribe(`Menu.${props.group_code}`)
      const agendaChannel = pusherRef.subscribe(`Agenda.${props.group_code}`)

      props.setPedleSocket({
        pusher: pusherRef,
        menuChannel: menuChannel,
        agendaChannel: agendaChannel,
      })
    }
  }, [props.group_code, props.access_token])

  useEffect(() => {
    if (groupCode.current !== props.group_code && props.group_code !== "") {
      // socket.current = io.connect(process.env.REACT_APP_WEBRTC_WS)

      socket.current = io(process.env.REACT_APP_WEBRTC_WS, {
        transports: ["websocket"],
        upgrade: false,
      })

      socket.current.on("allUsers", (users) => {
        onlineUsers.current = users
        console.log("Online:", onlineUsers.current)
      })

      socket.current.on("disconnect", () => {
        console.log("Disconnected..")
      })

      socket.current.on("reconnect", () => {
        console.log("Reconnecting..")
      })

      socket.current.on("connect", () => {
        console.log("Connect..")
        socket.current.emit("setUser", props.group_code)
      })

      if (navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      }

      socket.current.on("hey", (data) => {
        // setReceivingCall(true)
        setCaller(data.from)
        setCallerData(data.callerData)
        setCallerSignal(data.signal)
      })

      props.setStream({
        socket: socket.current,
        yourID: props.group_code,
      })
    }
    groupCode.current = props.group_code
  }, [props.group_code])

  useEffect(() => {
    if (caller !== "" && callerSignal) {
      if (
        props.router.location.pathname !== "/video-call" &&
        props.router.location.pathname !== "/in-call"
      ) {
        // console.log("SET STREAM-----", props.router.location.pathname)
        props.setStream({
          socket: socket.current,
          yourID: props.group_code,
          caller,
          callerData,
          callerSignal,
        })
        props.history.push("video-call")
      } else {
        // console.log("CURRENT PAGE:", props.router.location.pathname)
        // console.log("EMIT BUSY:", caller)
        socket.current.emit("userIsBusy", {
          data: {
            from: props.group_code,
          },
          to: caller,
        })
      }
    }
  }, [caller, callerSignal])

  useEffect(() => {
    menuList.current = props.menuList
  }, [props.menuList])

  useDeepEffect(() => {
    if (
      props.history.location.pathname === "/news" &&
      currentURL.current === props.router.location.pathname
    ) {
      // Force refresh
      props.history.push("/home")
      props.history.goBack()
    } else if (
      props.history.location.pathname === "/radio" &&
      currentURL.current === props.router.location.pathname
    ) {
      // Force refresh
      props.history.push("/home")
      // props.history.goBack();
    }

    currentURL.current = props.router.location.pathname
  }, [props.router.location])

  useEffect(() => {
    if (
      props.history.location.pathname === "/home" &&
      currentURL.current === props.router.location.pathname
    ) {
      sleepTimerRef.current = setTimeout(() => {
        props.history.push("sleep-screen")
      }, 5 * 60 * 1000)
    }

    return () => {
      if (sleepTimerRef.current !== undefined) {
        clearTimeout(sleepTimerRef.current)
        sleepTimerRef.current = undefined
      }
    }
  }, [currentURL.current, props.router.location])

  const handleUserKeyRelease = (event) => {
    const { key } = event

    if (
      props.history.location.pathname !== "/splash-screen" &&
      props.history.location.pathname !== "/pair-pedle-unit" &&
      props.history.location.pathname !== "/video-call" &&
      props.history.location.pathname !== "/in-call" &&
      props.modal.message === ""
    ) {
      if (
        props.history.location.pathname === "/sleep-screen" &&
        alarmDismissKeys.includes(key)
      ) {
        props.history.push("home")
      } else if (buttonNumberKeys.includes(key)) {
        _redirect(key)
      } else if (buttonCategoryKeys.includes(key)) {
        props.setMenuCategory(key)
        // if (
        //   props.history.location.pathname === "/home" &&
        //   menuList.current.category === key
        // ) {
        //   props.history.push("sleep-screen") // trigger sleep mode manually
        // } else {
        props.history.push("home")
        // }
      } else if (key === dotKey) {
        toast.dismiss()
      }
    }
  }

  const _redirect = (key) => {
    const keyIndex = _.findIndex(
      buttonNumberKeys,
      (currentKey) => currentKey === key
    )
    const menuCategory = menuList.current.category.toUpperCase()

    if (keyIndex > -1 && menuList.current.data[menuCategory].length > 0) {
      const menu = menuList.current.data[menuCategory][keyIndex]
      if (menu !== undefined && menu.value !== null) {
        const isVideoCallButton = isCallButton(menu)
        const isGamesButton = menu.value === "games"
        const screenName =
          isVideoCallButton === true
            ? "in-call"
            : isGamesButton === true && menu.details !== null
            ? menu.details
            : menu.value
        const screenStates = {
          url: menu.url,
          thumbnail_url: menu.thumbnail_url,
          details: menu.details,
          menu_info: menu,
        }

        if (isVideoCallButton === true) {
          const selectedUser = menu.details
          const id = selectedUser.groupCode
            ? selectedUser.groupCode
            : selectedUser.user_id

          props.setStream({
            socket: socket.current,
            yourID: groupCode.current,
            users: [
              {
                id,
                name: selectedUser.name,
                avatar: selectedUser.avatar,
                user_type: selectedUser.hasOwnProperty("groupCode")
                  ? "consoles"
                  : "members",
              },
            ],
          })
        }

        props.history.push(screenName, screenStates)
      } else {
        props.history.push("home")
      }
    }
  }

  const checkTrigger = (type, triggerTime, data) => {
    var now = new Date()
    var hr = _padZeroTimeHelper(now.getHours())
    var min = _padZeroTimeHelper(now.getMinutes())
    var sec = _padZeroTimeHelper(now.getSeconds())

    if (type !== undefined && triggerTime !== undefined && data !== undefined) {
      now = hr + min + sec
      if (now == triggerTime) {
        if (type === "alarm") {
          props.triggerModal({
            title: data.title,
            message: `${data.time} uur`,
            alarm: true,
            autoDismiss: true,
            timeout: 180000,
          })
        } else if (type === "reminder") {
          toast(ReminderToastComponent, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onOpen: () => {
              props.setToastStatus("reminder", true)
              reminderSound.currentTime = 0
              reminderSound.play()
            },
            onClose: () => {
              props.setToastStatus("reminder", false)
              reminderSound.pause()
            },
          })
        }
      }
    }
  }

  const updateNotifyTrigger = (type, data) => {
    if (type !== undefined && data !== undefined && data.length !== 0) {
      for (const elem of data) {
        var now = new Date()
        var hr = _padZeroTimeHelper(now.getHours())
        var min = _padZeroTimeHelper(now.getMinutes())
        var sec = _padZeroTimeHelper(now.getSeconds())
        now = hr + min + sec

        const elementTime = type === "reminder" ? elem.reminderTime : elem.time
        const timeParsed = _parseTimeString(`${elementTime}:00`) // HH:MM:SS
        const elemTime =
          _padZeroTimeHelper(timeParsed.hour) +
          _padZeroTimeHelper(timeParsed.minute) +
          _padZeroTimeHelper(timeParsed.seconds)

        if (now > elemTime) continue // ignore passed moments

        if (now !== elemTime) {
          // console.log("SET TRIGGER", type, elem);

          if (type === "alarm") {
            setAlarmTime(elemTime)
            setAlarmData(elem)
          } else if (type === "reminder") {
            setReminderTime(elemTime)
            setReminderData(elem)
          }
        }
        break
      }
    }
  }

  useEffect(() => {
    if (
      excludedScreensForAlarmReminders.includes(
        props.history.location.pathname
      ) &&
      currentURL.current === props.router.location.pathname
    ) {
      if (checkAlarmInterval.current) {
        clearInterval(checkAlarmInterval.current)
        clearInterval(updateAlarmInterval.current)
      }
    } else {
      updateNotifyTrigger("alarm", props.upcomingAlarms)
      checkAlarmInterval.current = setInterval(
        checkTrigger,
        1000,
        "alarm",
        alarmTime,
        alarmData
      )

      updateAlarmInterval.current = setInterval(
        updateNotifyTrigger,
        60000,
        "alarm",
        props.upcomingAlarms
      )
    }

    return () => {
      clearInterval(checkAlarmInterval.current)
      clearInterval(updateAlarmInterval.current)
    }
  }, [
    props.upcomingAlarms,
    alarmTime,
    alarmData,
    currentURL.current,
    props.router.location,
  ])

  useEffect(() => {
    if (
      excludedScreensForAlarmReminders.includes(
        props.history.location.pathname
      ) &&
      currentURL.current === props.router.location.pathname
    ) {
      if (checkReminderInterval.current) {
        clearInterval(checkReminderInterval.current)
        clearInterval(updateReminderInterval.current)
      }
    } else {
      if (upcomingRemindersRef.current !== props.upcomingReminders) {
        updateNotifyTrigger("reminder", props.upcomingReminders)
      }
      upcomingRemindersRef.current = props.upcomingReminders

      checkReminderInterval.current = setInterval(
        checkTrigger,
        1000,
        "reminder",
        reminderTime,
        reminderData
      )
      updateReminderInterval.current = setInterval(
        updateNotifyTrigger,
        60000,
        "reminder",
        props.upcomingReminders
      )
    }

    return () => {
      clearInterval(checkReminderInterval.current)
      clearInterval(updateReminderInterval.current)
    }
  }, [
    props.upcomingReminders,
    reminderTime,
    reminderData,
    currentURL.current,
    props.router.location,
  ])

  const ReminderToastComponent = () => {
    return (
      <React.Fragment>
        {reminderData !== undefined && reminderData.length !== 0 && (
          <ReminderToast>
            <img src={iconBell} width={20} alt="" />
            <ReminderContent className={"font-Roboto"}>
              <ReminderTitle>{reminderData.title}</ReminderTitle>
              <ReminderTime>{reminderData.datetimeFormatted}</ReminderTime>
            </ReminderContent>
          </ReminderToast>
        )}
      </React.Fragment>
    )
  }

  const { loader, history, modal } = props

  return (
    <Suspense fallback={<Loader />}>
      <ConnectedRouter history={history}>
        <MainContainer onContextMenu={(e) => e.preventDefault()}>
          {loader && <Loader />}
          {modal.message !== "" && <Modal history={history} />}
          <StyledToastContainer
            position="bottom-right"
            autoClose={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />
          <AllRoutes />
        </MainContainer>
      </ConnectedRouter>
    </Suspense>
  )
}

const MainContainer = styled.div`
  background-color: ${Color.darkestBlue};
  height: 100%;
  width: 100%;
  user-select: none;
`

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast--default {
    background-color: ${Color.darkerBlue};
  }
  .Toastify__close-button--default {
    color: ${Color.white};
  }
`

const ReminderToast = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
`

const ReminderContent = styled.div`
  flex-grow: 1;
  padding-left: 15px;
`

const ReminderTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
`

const ReminderTime = styled.div``

const mapStateToProps = (state) => {
  return {
    group_code: state.Console.group_code,
    access_token: state.Console.access_token,
    loader: state.App.loader,
    modal: state.App.modal,
    menuList: state.Console.menuList,
    router: state.router,
    upcomingAlarms: state.Agenda.upcomingAlarms,
    upcomingReminders: state.Agenda.upcomingReminders,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStream: (params) => dispatch(setStream(params)),
    setPedleSocket: (params) => dispatch(setPedleSocket(params)),
    triggerModal: (params) => dispatch(triggerModal(params)),
    setToastStatus: (param1, param2) =>
      dispatch(setToastStatus(param1, param2)),
    setMenuCategory: (params) => dispatch(setMenuCategory(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
