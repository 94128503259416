import * as actionTypes from "../actions/actionTypes"
import produce from "immer"

const INITIAL_STATE = {
  loader: false,
  modal: {
    title: "",
    message: "",
    autoDismiss: false,
    timeout: 3000,
    alarm: false,
    footer: true,
    // showActionButton: true,
  },
  appToast: {
    reminder: false,
  },
}

export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOADER:
      return produce(state, (draft) => {
        draft.loader = action.enable
      })

    // Modal
    case actionTypes.TRIGGER_MODAL:
      return produce(state, (draft) => {
        draft.modal = { ...state.modal, ...action.data }
      })

    case actionTypes.DISMISS_MODAL: {
      return produce(state, (draft) => {
        draft.modal = INITIAL_STATE.modal
      })
    }

    // Toast
    case actionTypes.SET_TOAST_STATUS:
      return produce(state, (draft) => {
        draft.appToast = {
          ...state.appToast,
          [action.data.type]: action.data.status,
        }
      })

    default:
      return state
  }
}
