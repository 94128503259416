import api from "./../../utils/api"
// import { store } from './../../redux/store';

// const pathURL = "weather";

export const getWeather = (weatherUrl) => {
  const url = `${weatherUrl}`

  return api.get(url, {
    baseURL: "",
  })
}
