import * as actionTypes from "./actionTypes";

export const getWeatherList = (data) => {
  return {
    type: actionTypes.REQUEST_GET_WEATHER_LIST,
    data,
  };
};

export const getWeatherListSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_WEATHER_LIST,
    data,
  };
};

export const getWeatherListFail = () => {
  return {
    type: actionTypes.FAIL_GET_WEATHER_LIST,
  };
};
