import * as actionTypes from "./actionTypes";

export const getRadio = (data) => {
  return {
    type: actionTypes.REQUEST_GET_RADIO,
    data,
  };
};

export const getRadioSuccess = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_RADIO,
    data,
  };
};

export const getRadioFail = () => {
  return {
    type: actionTypes.FAIL_GET_RADIO,
  };
};
