import React from "react"
import { LargeClock } from "../molecules"
import styled from "styled-components"
import { Header, Footer } from "../atoms"

const HomeLayout = (props) => {
  return (
    <OuterContainer isSleepMode={props.isSleepMode} onClick={props.onClick}>
      <Header isShowMenuCategory isSleepMode={props.isSleepMode}>
        Welkom {props?.groupDetails?.name || ""}
      </Header>
      <MainContainer>
        <LeftWrapper>{props.leftContent}</LeftWrapper>
        <RightWrapper>
          <LargeClock
            timeWrapperStyle={props.timeWrapperStyle}
            clockWrapperStyle={props.clockWrapperStyle}
            isSleepMode={props.isSleepMode}
          />
          {props.rightContent}
        </RightWrapper>
      </MainContainer>
      <Footer isShowMenuCategory isSleepMode={props.isSleepMode} />
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  ${(props) => (props.isSleepMode === true ? `background-color: #000;` : "")}
`

const MainContainer = styled.div`
  display: flex;
  flex-grow: 1;
`

const LeftWrapper = styled.div`
  min-width: 62vw;
  max-width: 62vw;
  display: flex;
  align-items: stretch;
`

const RightWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`

export default HomeLayout
