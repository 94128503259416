import Color from "../assets/color"

import consoleStar from "../assets/icons/controller/star.svg"
import consoleMusic from "../assets/icons/controller/music.svg"
import consoleGames from "../assets/icons/controller/games.svg"
import consoleCall from "../assets/icons/controller/call.svg"
import consoleMenu from "../assets/icons/controller/menu.svg"

export const ConsoleColor = [
  Color.primaryYellow,
  Color.primaryBlue,
  Color.primaryRed,
  Color.primaryGreen,
  Color.primaryOrange,
]

export const buttonCategories = [
  // {
  //   type: "a",
  //   icon: consoleStar,
  // },
  // {
  //   type: "s",
  //   icon: consoleMusic,
  // },
  // {
  //   type: "d",
  //   icon: consoleGames,
  // },
  {
    type: "f",
    icon: consoleCall,
  },
  {
    type: "j",
    icon: consoleMenu,
  },
]

const getButtonCategoryKeys = () => {
  const arr = []
  buttonCategories.map((item) => arr.push(item.type))
  return arr
}

export const getButtonIconByType = (type) => {
  const keyIndex = buttonCategories.findIndex((item) => item.type === type)
  return buttonCategories[keyIndex].icon
}

export const pairingKeys = ["j", "m"]

export const dotKey = "m"

export const prevArrowKey = "c"
export const nextArrowKey = "v"
export const arrowKeys = [...prevArrowKey, ...nextArrowKey]

export const defaultCategoryMenu = "j"
export const buttonCategoryKeys = getButtonCategoryKeys()

export const buttonNumberKeys = ["q", "w", "e", "r", "u"]

export const defaultDismissKeys = [...buttonCategoryKeys, ...dotKey]

export const alarmDismissKeys = [
  ...buttonCategoryKeys,
  ...buttonNumberKeys,
  ...dotKey,
  ...arrowKeys,
]

export const peerConfig = {
  trickle: false,
  config: {
    // iceTransportPolicy: "relay",
    iceServers: [
      {
        urls: [process.env.REACT_APP_STUN_URL],
      },
      {
        urls: [process.env.REACT_APP_TURN_URL],
        username: process.env.REACT_APP_TURN_USERNAME,
        credential: process.env.REACT_APP_TURN_CREDENTIAL,
      },
      // {
      //   urls: ["stun:global.stun.twilio.com:3478?transport=udp"],
      // },
      // {
      //   urls: [
      //     "turn:global.turn.twilio.com:3478?transport=udp",
      //     "turn:global.turn.twilio.com:3478?transport=tcp",
      //     "turn:global.turn.twilio.com:443?transport=tcp",
      //   ],
      //   username:
      //     "57f937707033a4021be479e9bf1c08a323cca9d877f438f17f7512c84d65d2db",
      //   credential: "0S6AIjxw6RzdiVXaDsClmAtDXar6l1JDDtK5vHgVl80=",
      // },
    ],
  },
}
