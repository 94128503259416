import React from "react"
import styled from "styled-components"
import Color from "../../assets/color"

const ControllerButton = (props) => {
  return <Button {...props}>{props.children}</Button>
}

const Button = styled.div`
  width: 9.375vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: relative;

  img {
    width: 66.667%;
  }

  &.active-menu {
    background-color: ${Color.blueBayoux};
    border-bottom-left-radius: 6px;

    &:not(.is-last-item) {
      border-bottom-right-radius: 6px;
    }
  }

  &.shadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`

export default ControllerButton
