import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import Color from "./../assets/color"

import { MainContainer, Header, Footer } from "../components/atoms"
import alarmIconBig from "./../assets/icons/alarm-icon-big-animated.gif"

import {
  dotKey,
  defaultCategoryMenu,
  defaultDismissKeys,
  alarmDismissKeys,
} from "../utils/constant"
import { _triggerKeyPress } from "../utils/helper"

import { dismissModal, setStream } from "../redux/actions"

const Modal = (props) => {
  const [isGamesScreen, setIsGamesScreen] = useState(false)
  const ringtone = new Audio(require("./../assets/sounds/alarm.mp3"))

  useEffect(() => {
    if (props.history.location?.state?.menu_info?.value === "games") {
      setIsGamesScreen(!isGamesScreen)
    }
  }, [props?.history?.location])

  useEffect(() => {
    if (ringtone && props.modal.alarm === true) {
      ringtone.currentTime = 0
      ringtone.loop = true
      ringtone.play()
    }

    return () => {
      if (ringtone && props.modal.alarm === true) ringtone.pause()
    }
  }, [ringtone, props.modal.alarm])

  const closeModal = () => {
    if (isGamesScreen && props.modal.alarm === false) {
      // push to home if receive call modal
      props.history.push("home")
    }
    props.dismissModal()
  }

  const handleUserKeyRelease = (event) => {
    const { key } = event

    if (props.modal.alarm === true && alarmDismissKeys.includes(key)) {
      closeModal()
    } else {
      if (defaultDismissKeys.includes(key)) {
        closeModal()
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyRelease)
    return () => {
      window.removeEventListener("keyup", handleUserKeyRelease)
    }
  }, [])

  useEffect(() => {
    if (props.modal.autoDismiss) {
      const autoDismissTimeout =
        props.modal.timeout !== null && props.modal.timeout !== ""
          ? props.modal.timeout
          : 3000

      setTimeout(() => {
        closeModal()
      }, autoDismissTimeout)
    }
  }, [props.modal.autoDismiss, props.modal.timeout])

  return (
    <StyledMainContainer modalActive={props.modal.message !== ""}>
      {props.modal.alarm === true ? (
        <React.Fragment>
          <Header
            isModalScreen
            modalProps={{
              onClickHome: () =>
                isGamesScreen
                  ? _triggerKeyPress("keyup", dotKey)
                  : _triggerKeyPress("keyup", defaultCategoryMenu),
            }}
          ></Header>
          <ModalContent footerEnabled={props.modal.footer}>
            <ModalMessage alarmEnabled={props.modal.alarm}>
              {props.modal.title}
            </ModalMessage>
            <img src={alarmIconBig} width={420} alt="" />
            <ModalMessage alarmEnabled={props.modal.alarm}>
              {props.modal.message}
            </ModalMessage>
          </ModalContent>
          {props.modal.footer === true && (
            <Footer
              isModalScreen
              modalProps={{
                showActionButton: true,
                onClickActionButton: () => _triggerKeyPress("keyup", dotKey),
              }}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header
            isModalScreen
            modalProps={{
              onClickHome: () => _triggerKeyPress("keyup", defaultCategoryMenu),
            }}
          >
            {props?.modal?.title || ""}
          </Header>
          <ModalContent footerEnabled={props.modal.footer}>
            <ModalMessage>{props.modal.message}</ModalMessage>
          </ModalContent>
          {props.modal.footer === true && (
            <Footer
              isModalScreen
              modalProps={{
                showActionButton: true,
                onClickActionButton: () => _triggerKeyPress("keyup", dotKey),
              }}
            />
          )}
        </React.Fragment>
      )}
    </StyledMainContainer>
  )
}

const StyledMainContainer = styled(MainContainer)`
  ${(props) =>
    props.modalActive === true &&
    `
  background-color: ${Color.darkestBlue};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 11;
`};
`

const ModalContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ModalMessage = styled.div`
  color: ${Color.white};
  font-size: ${(props) =>
    (props.alarmEnabled === false && "2.65vw") || "2.65vw"};
  font-weight: bold;
  text-align: center;
`

const mapStateToProps = (state) => {
  return {
    modal: state.App.modal,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dismissModal: () => dispatch(dismissModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
