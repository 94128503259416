import React, { forwardRef, useCallback, useEffect } from "react"
import styled from "styled-components"

import { ControllerButton } from "./../atoms"

import arrowPrev from "../../assets/icons/controller/arrow-prev.svg"
import arrowNext from "../../assets/icons/controller/arrow-next.svg"
import dotButton from "../../assets/icons/controller/dot.svg"

import { arrowKeys, prevArrowKey, nextArrowKey } from "../../utils/constant"
import { _triggerKeyPress } from "../../utils/helper"

const SliderController = forwardRef((props, ref) => {
  const {
    currentSlide,
    numberOfSlide,
    // title
  } = props

  const handleUserKeyPress = useCallback(
    (event) => {
      const { key } = event

      if (numberOfSlide !== undefined && arrowKeys.includes(key)) {
        switch (key) {
          case prevArrowKey: {
            return ref.current.slickPrev()
          }
          case nextArrowKey: {
            return ref.current.slickNext()
          }
          default:
            break
        }
      }
    },
    [ref, numberOfSlide]
  )

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  return (
    <ControllerWrapper>
      {numberOfSlide !== undefined && (
        <React.Fragment>
          {props.isShowPagination && (
            <SliderNumber>
              {currentSlide} van {numberOfSlide}
            </SliderNumber>
          )}

          <ControllerButton
            onClick={() => _triggerKeyPress("keydown", prevArrowKey)}
          >
            <img src={arrowPrev} alt="" />
          </ControllerButton>
          <ControllerButton
            onClick={() => _triggerKeyPress("keydown", nextArrowKey)}
          >
            <img src={arrowNext} alt="" />
          </ControllerButton>
        </React.Fragment>
      )}
      <ControllerButton />
      {props.actionButton === true && props.onClickActionButton && (
        <ControllerButton onClick={props.onClickActionButton}>
          <img src={dotButton} alt="" />
        </ControllerButton>
      )}
    </ControllerWrapper>
  )
})

export default SliderController

const ControllerWrapper = styled.div`
  display: flex;
`

const SliderNumber = styled.div`
  align-self: center;
`
