import React from "react"
import styled from "styled-components"
import Color from "./../../assets/color"

const Content = (props) => {
  return <ContentWrapper {...props}>{props.children}</ContentWrapper>
}

const ContentWrapper = styled.div`
  flex-grow: 1;
`

export default Content
