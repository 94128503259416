// Credit: https://github.com/pvoznyuk/react-live-clock
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import "moment/locale/nl";

const getDate = (date) =>
  date ? new Date(date).getTime() : new Date().getTime();

const ReactLiveClock = (props) => {
  const { children, date, format, timezone, ticking, interval } = props;

  // eslint-disable-next-line
  const [value, setValue] = useState(0);

  const forceUpdate = useCallback(
    () =>
      setInterval(
        () => {
          // update the state to force render
          setValue((value) => ++value);
        },
        interval ? interval : 1000
      ),
    [interval]
  );

  useEffect(() => {
    if (ticking) {
      forceUpdate();
    }
  }, [forceUpdate, ticking, interval]);

  const dateValue = getDate(date || children);
  moment.updateLocale("nl", {
    monthsShort: moment.monthsShort("-MMM-"),
  });

  moment.locale("nl");
  const localizedTime = moment(dateValue);

  if (timezone) {
    localizedTime.tz(timezone);
  }

  const formattedTime = localizedTime.format(format);

  return <time>{formattedTime}</time>;
};

export default ReactLiveClock;
