import { all, takeEvery, put, fork } from "redux-saga/effects";
// import { push } from 'redux-first-history';

import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
// import { toast } from "react-toastify";

export function* getWeatherList() {
  yield takeEvery(actionTypes.REQUEST_GET_WEATHER_LIST, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.getWeather(action.data);
      if (response.status === 200 && response.data.liveweer) {
        yield put(actions.getWeatherListSuccess(response.data.liveweer));
      } else {
        yield put(actions.getWeatherListFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.getWeatherListFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* messageSaga() {
  yield all([fork(getWeatherList)]);
}
