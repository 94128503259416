/* ===== APP ===== */
export const LOADER = "LOADER"

export const TRIGGER_MODAL = "TRIGGER_MODAL"
export const DISMISS_MODAL = "DISMISS_MODAL"

export const SET_TOAST_STATUS = "SET_TOAST_STATUS"
/* ===== END APP ===== */

/* ===== CONSOLE ===== */
export const REQUEST_PAIRING = "REQUEST_PAIRING"
export const SUCCESS_PAIRING = "SUCCESS_PAIRING"
export const FAIL_PAIRING = "FAIL_PAIRING"

export const REQUEST_CHECK_PAIRING = "REQUEST_CHECK_PAIRING"
export const SUCCESS_CHECK_PAIRING = "SUCCESS_CHECK_PAIRING"
export const FAIL_CHECK_PAIRING = "FAIL_CHECK_PAIRING"

export const REQUEST_GET_MENU_LIST = "REQUEST_GET_MENU_LIST"
export const SUCCESS_GET_MENU_LIST = "SUCCESS_GET_MENU_LIST"
export const FAIL_GET_MENU_LIST = "FAIL_GET_MENU_LIST"
export const SET_PEDLE_SOCKET = "SET_PEDLE_SOCKET"
export const SET_MENU_CATEGORY = "SET_MENU_CATEGORY"

export const REQUEST_GET_SERIAL_NUMBER = "REQUEST_GET_SERIAL_NUMBER"
export const SUCCESS_GET_SERIAL_NUMBER = "SUCCESS_GET_SERIAL_NUMBER"
export const FAIL_GET_SERIAL_NUMBER = "FAIL_GET_SERIAL_NUMBER"

export const SET_STREAM = "SET_STREAM"
export const CLEAR_STREAM = "CLEAR_STREAM"

export const REQUEST_CREATE_ACTIVITY_LOG = "REQUEST_CREATE_ACTIVITY_LOG"
// export const SUCCESS_CREATE_ACTIVITY_LOG = "SUCCESS_CREATE_ACTIVITY_LOG"
// export const FAIL_CREATE_ACTIVITY_LOG = "FAIL_CREATE_ACTIVITY_LOG"
/* ===== END CONSOLE ===== */

export const REQUEST_GET_AGENDA_LIST = "REQUEST_GET_AGENDA_LIST"
export const SUCCESS_GET_AGENDA_LIST = "SUCCESS_GET_AGENDA_LIST"
export const FAIL_GET_AGENDA_LIST = "FAIL_GET_AGENDA_LIST"

export const SET_ALARMS = "SET_ALARMS"
export const SET_REMINDERS = "SET_REMINDERS"

export const REQUEST_GET_CONTACT_LIST = "REQUEST_GET_CONTACT_LIST"
export const SUCCESS_GET_CONTACT_LIST = "SUCCESS_GET_CONTACT_LIST"
export const FAIL_GET_CONTACT_LIST = "FAIL_GET_CONTACT_LIST"

export const REQUEST_GET_MESSAGE_LIST = "REQUEST_GET_MESSAGE_LIST"
export const SUCCESS_GET_MESSAGE_LIST = "SUCCESS_GET_MESSAGE_LIST"
export const FAIL_GET_MESSAGE_LIST = "FAIL_GET_MESSAGE_LIST"

export const REQUEST_GET_NEWS = "REQUEST_GET_NEWS"
export const SUCCESS_GET_NEWS = "SUCCESS_GET_NEWS"
export const FAIL_GET_NEWS = "FAIL_GET_NEWS"

export const REQUEST_GET_RADIO = "REQUEST_GET_RADIO"
export const SUCCESS_GET_RADIO = "SUCCESS_GET_RADIO"
export const FAIL_GET_RADIO = "FAIL_GET_RADIO"

export const REQUEST_GET_WEATHER_LIST = "REQUEST_GET_WEATHER_LIST"
export const SUCCESS_GET_WEATHER_LIST = "SUCCESS_GET_WEATHER_LIST"
export const FAIL_GET_WEATHER_LIST = "FAIL_GET_WEATHER_LIST"
