import { all, takeEvery, put, fork } from "redux-saga/effects";
// import { push } from 'redux-first-history';

import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
// import * as API from "../api";
// import { toast } from "react-toastify";

export function* getRadio() {
  yield takeEvery(actionTypes.REQUEST_GET_RADIO, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = null; //= yield API.login(action.data);
      if (response.data) {
        yield put(actions.getRadioSuccess(response.data));
      } else {
        yield put(actions.getRadioFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.getRadioFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* radioSaga() {
  yield all([fork(getRadio)]);
}
