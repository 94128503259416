import React from "react"
import styled from "styled-components"

const CallPartnerThumbnail = (props) => {
  return (
    <PartnerThumbnail src={props.src} className={props.className} {...props} />
  )
}

const PartnerThumbnail = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 10%;

  &.mr-4 {
    margin-right: 1rem;
  }
  &.mr-8 {
    margin-right: 2rem;
  }

  &.ml-4 {
    margin-left: 1rem;
  }
  &.ml-8 {
    margin-left: 2rem;
  }

  &.size-medium {
    width: 150px;
    height: 150px;
  }

  &.size-large {
    width: 300px;
    height: 300px;
  }

  &.call-ongoing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${(props) => (!props.isSpeakerOn ? 1 : 0)};
  }

  &.call-outgoing {
    animation: pulse 3.07s infinite 2s;
  }
`

export default CallPartnerThumbnail
