import React from "react"
import styled, { keyframes } from "styled-components"

import Color from "../../assets/color"
import { ConsoleColor, buttonNumberKeys } from "../../utils/constant"
import { _triggerKeyPress } from "../../utils/helper"

const NumberedButtons = (props) => {
  const TOTAL_BUTTONS = 5

  const list = new Array(TOTAL_BUTTONS).fill({
    image: null,
    label: null,
    class: null,
    onClick: null,
  })

  return (
    <ConsoleButtonList style={props.listCss}>
      {list.map((item, index) => {
        const itemData = props.items ? props.items[index] : undefined

        let buttonOnClick = null

        if (itemData !== undefined && props.triggerButtonPress === undefined) {
          buttonOnClick = itemData.onClick
        }

        if (itemData === undefined && props.triggerButtonPress !== undefined) {
          const evtType = props.triggerButtonPress
          buttonOnClick = () =>
            _triggerKeyPress(evtType, buttonNumberKeys[index])
        }

        return (
          <ConsoleButton
            key={index}
            type={index}
            onClick={buttonOnClick}
            className={itemData !== undefined && itemData?.class}
          >
            <ButtonNumber type={index}>{index + 1}</ButtonNumber>
            {itemData !== undefined && itemData?.image !== null && (
              <ButtonImage src={itemData?.image} />
            )}
            {itemData !== undefined && (
              <ButtonTitle className={itemData?.class}>
                {itemData?.label}
              </ButtonTitle>
            )}
          </ConsoleButton>
        )
      })}
    </ConsoleButtonList>
  )
}

const ConsoleButtonList = styled.div`
  width: 100%;
  padding: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const changeBackgroundColor = (type) => keyframes`
from {background-color: transparent;}
  to {background-color:  ${
    type === undefined ? Color.blue : ConsoleColor[parseInt(type)]
  };}
`

const ConsoleButton = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50px;

  &.pressed {
    animation: ${(props) => changeBackgroundColor(props.type)} 500ms forwards;
  }
`

const ButtonNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.type === undefined ? Color.blue : ConsoleColor[parseInt(props.type)]};
  color: ${Color.black};
  border-radius: 50%;
  font-size: 3.125vw;
  font-weight: 900;
  line-height: 1;
  min-width: 80px;

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }
`

const ButtonTitle = styled.div`
  font-size: 2.96vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.25rem;
  color: ${Color.white};
  font-weight: 500;
  line-height: 1;

  &.pressed {
    color: ${Color.black};
  }

  &.is-video-call-button {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: 1em;
      margin-left: 0.25em;
    }
  }
`

const ButtonImage = styled.div`
  border-radius: 6px;
  margin-left: 1.25rem;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 80px;

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }
`

export default NumberedButtons
