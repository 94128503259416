import { all, takeEvery, put, fork } from "redux-saga/effects";
// import { push } from 'redux-first-history';
import convert from "xml-js";

import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";

import { _filterNewsList } from "./../../utils/helper";

export function* getNewsList() {
  yield takeEvery(actionTypes.REQUEST_GET_NEWS, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.getNews(action.data);

      if (response.status === 200) {
        const newstoJSON = yield convert.xml2json(response.data, {
          compact: true,
        });

        const filterNewsList = _filterNewsList(JSON.parse(newstoJSON));
        yield put(actions.getNewsListSuccess(filterNewsList));
      } else {
        yield put(actions.getNewsListFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.getNewsListFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* newsSaga() {
  yield all([fork(getNewsList)]);
}
