import * as actionTypes from "../actions/actionTypes"
import produce from "immer"
import { defaultCategoryMenu } from "../../utils/constant"

const INITIAL_STATE = {
  access_token: "",
  serial_number: process.env.REACT_APP_SERIAL_NUMBER,
  group_code: "",
  groupDetails: {
    name: null,
    avatar: null,
  },
  connectConsole: {
    isSubmitted: false,
    isSuccess: false,
  },
  menuList: {
    data: {},
    category: defaultCategoryMenu,
    isLoading: false,
  },
  pedleSocket: {
    pusher: null,
    menuChannel: null,
    agendaChannel: null,
  },
  videoCall: {
    users: null,
    yourID: null,
    socket: null,
    caller: null,
    callerData: null,
    callerSignal: null,
  },
}

export default function consoleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Pairing
    case actionTypes.REQUEST_PAIRING: {
      return produce(state, (draft) => {
        draft.connectConsole.isSubmitted = false
        draft.connectConsole.isSuccess = false
        draft.access_token = null
      })
    }
    case actionTypes.SUCCESS_PAIRING: {
      return produce(state, (draft) => {
        draft.connectConsole.isSubmitted = true
        draft.connectConsole.isSuccess = true
        draft.access_token = action.data.token
        draft.group_code = action.data.group_code
        draft.groupDetails = action.data.groupDetails
      })
    }
    case actionTypes.FAIL_PAIRING: {
      return produce(state, (draft) => {
        draft.connectConsole.isSubmitted = true
        draft.connectConsole.isSuccess = false
        draft.access_token = null
      })
    }
    // Check Pairing
    case actionTypes.SUCCESS_CHECK_PAIRING: {
      return produce(state, (draft) => {
        draft.access_token = action.data.token
        draft.group_code = action.data.group_code
        draft.groupDetails = action.data.groupDetails
      })
    }
    // Menu
    case actionTypes.REQUEST_GET_MENU_LIST: {
      return produce(state, (draft) => {
        draft.menuList.data = []
        draft.menuList.isLoading = true
      })
    }
    case actionTypes.SUCCESS_GET_MENU_LIST: {
      return produce(state, (draft) => {
        draft.menuList.data = action.data
        draft.menuList.isLoading = false
      })
    }
    case actionTypes.FAIL_GET_MENU_LIST: {
      return produce(state, (draft) => {
        draft.menuList.isLoading = false
      })
    }
    case actionTypes.SET_PEDLE_SOCKET: {
      return produce(state, (draft) => {
        draft.pedleSocket = action.data
      })
    }
    case actionTypes.SET_MENU_CATEGORY: {
      return produce(state, (draft) => {
        draft.menuList.category = action.data
      })
    }
    // Serial number
    case actionTypes.REQUEST_GET_SERIAL_NUMBER: {
      return produce(state, (draft) => {
        draft.serial_number = null
      })
    }
    case actionTypes.SUCCESS_GET_SERIAL_NUMBER: {
      return produce(state, (draft) => {
        draft.serial_number = action.data
      })
    }
    case actionTypes.FAIL_GET_SERIAL_NUMBER: {
      return produce(state, (draft) => {
        draft.serial_number = null
      })
    }
    // Stream
    case actionTypes.SET_STREAM: {
      return produce(state, (draft) => {
        draft.videoCall = action.data
      })
    }
    case actionTypes.CLEAR_STREAM: {
      return produce(state, (draft) => {
        draft.videoCall = {
          users: INITIAL_STATE.users,
          caller: INITIAL_STATE.caller,
          callerData: INITIAL_STATE.callerData,
          callerSignal: INITIAL_STATE.callerSignal,
        }
      })
    }
    default:
      return state
  }
}
