import React from "react";
import styled from "styled-components";

const Container = (props) => {
  return <ContainerWrapper {...props}>{props.children}</ContainerWrapper>;
};

const ContainerWrapper = styled.div``;

export default Container;
