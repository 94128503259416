import React, { forwardRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReactSlickSlider = forwardRef((props, ref) => {
  const { setCurrentSlide } = props;

  let settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    infinite: true,
    fade: true,
    beforeChange: (current, next) => setCurrentSlide(next + 1),
  };

  return (
    <StyledSlider ref={ref} {...settings}>
      {props.children}
    </StyledSlider>
  );
});

const StyledSlider = styled(Slider)`
  &.slick-slider {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
  }

  .slick-list,
  .slick-track {
    display: flex;
    flex-grow: 1;
  }

  .slick-slide,
  .slick-slide > div {
    display: flex;
    flex-grow: 1;
  }
`;

export default ReactSlickSlider;
